import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
  unwrapResult,
} from "@reduxjs/toolkit";
import * as assignmentAPI from "services/assignment";
import {
  AssignmentModel,
  RedeemableProductModel,
  RedemptionModel,
  VoucherGroupModel,
  VoucherType,
} from "types/model/assignment";
import {
  AssignmentAndRedemptionListParams,
  AssignmentListParams,
  RedemptionListParams,
  SwitchCourseParams,
  VoucherDistributionListParams,
  VoucherSummaryParams,
  VoucherAssignmentRequest,
  RevokingRequest,
  VoucherReassignmentRequest,
} from "types/dto/request/assignment";
import { RootState } from "../../app/store";
import {
  AssignmentAndRedemptionListResponse,
  VoucherCodeInformation,
  VoucherSummaryResponse,
} from "../../types/dto/response/assignment";

export const fetchVoucherDistributions = createAsyncThunk(
  "voucher-groups/fetchAll",
  async (
    payload: VoucherDistributionListParams & { customerGroupId: string }
  ) => {
    const { customerGroupId, ...params } = payload;
    return await assignmentAPI.fetchAll(customerGroupId, {
      size: 10,
      ...params,
      page: params.page ? params.page - 1 : 0,
    });
  }
);

export const fetchAssignmentAndRedemptionList = createAsyncThunk(
  "assignment/fetchAssignmentsAndRedemptions",
  (params: AssignmentAndRedemptionListParams) => {
    return assignmentAPI.fetchAssignmentAndRedemptionList(params);
  }
);

export const fetchAssignmentList = createAsyncThunk(
  "assignment/fetchAssignments",
  (params: AssignmentListParams) => {
    return assignmentAPI.fetchAssignmentList(params);
  }
);

export const fetchRedemptionList = createAsyncThunk(
  "assignment/fetchRedemptions",
  (params: RedemptionListParams) => {
    return assignmentAPI.fetchRedemptionList(params);
  }
);

export const fetchSeatAssignmentList = createAsyncThunk(
  "assignment/fetchSeatAssignments",
  (params: AssignmentAndRedemptionListParams) => {
    return assignmentAPI.fetchSeatAssignmentList(params);
  }
);

export const fetchRedeemableProducts = createAsyncThunk(
  "assignment/redeemableProducts",
  assignmentAPI.fetchRedeemableProducts
);

const getListFilter = (getState: () => unknown) => {
  const { voucherGroups } = getState() as RootState;
  return voucherGroups.filter;
};

export const switchCourse = createAsyncThunk(
  "assignment/switchCourse",
  (payload: SwitchCourseParams & { voucherCode: string }) => {
    const { voucherCode, ...data } = payload;
    return assignmentAPI.switchCourse(voucherCode, data);
  }
);

export const switchGroup = createAsyncThunk(
  "assignment/switchGroup",
  assignmentAPI.switchGroup
);

export const bulkSwitchGroup = createAsyncThunk(
  "assignment/bulkSwitchGroup",
  assignmentAPI.bulkSwitchGroup
);

export const resetPassword = createAsyncThunk(
  "assignment/resetPassword",
  assignmentAPI.resetPassword
);

export const sendSeatEmail = createAsyncThunk(
  "assignment/sendSeatEmail",
  assignmentAPI.sendSeatEmail
);

export const sendVoucherEmail = createAsyncThunk(
  "assignment/sendVoucherEmail",
  assignmentAPI.sendVoucherEmail
);

export const fetchVoucherSummary = createAsyncThunk(
  "assignment/fetchVoucherSummary",
  assignmentAPI.fetchVoucherSummary
);

export const assignVouchers = createAsyncThunk(
  "vouchers/assign",
  async (params: VoucherAssignmentRequest, { dispatch }) => {
    await assignmentAPI.assignVouchers(params);
    const { voucherGroupId, adminGroupId } = params;
    unwrapResult(
      await dispatch(fetchVoucherSummary({ adminGroupId, voucherGroupId }))
    );
  }
);

export const revokeVoucher = createAsyncThunk(
  "voucher/revoke",
  async (
    payload: RevokingRequest & { voucherId: number },
    { dispatch, getState }
  ) => {
    const { voucherId, ...data } = payload;
    await assignmentAPI.revoke(voucherId, data);
    const { voucherGroupId, adminGroupId } = getListFilter(getState);
    await dispatch(fetchVoucherSummary({ adminGroupId, voucherGroupId }));
  }
);

export const revokingRedeemedSingle = createAsyncThunk(
  "voucher/revokingRedeemedSingle",
  async (voucherId: number, { dispatch, getState }) => {
    await assignmentAPI.revokingRedeemedSingle(voucherId);
    const { voucherGroupId, adminGroupId } = getListFilter(getState);
    await dispatch(fetchVoucherSummary({ adminGroupId, voucherGroupId }));
  }
);

export const reassignVoucher = createAsyncThunk(
  "voucher/reassign",
  assignmentAPI.reassignVoucher
);

export const reassignRedeemedSingleVoucher = createAsyncThunk(
  "voucher/reassignRedeemedSingleVoucher",
  async (params: VoucherReassignmentRequest, { dispatch, getState }) => {
    await assignmentAPI.reassignRedeemedSingleVoucher(params);
    const { voucherGroupId, adminGroupId } = getListFilter(getState);
    await dispatch(fetchVoucherSummary({ adminGroupId, voucherGroupId }));
  }
);

export const reassignSeat = createAsyncThunk(
  "voucher/reassignSeat",
  assignmentAPI.reassignSeat
);

export const exportAssignmentAndRedemptionList = createAsyncThunk(
  "assignment/exportAssignmentAndRedemptionList",
  (params: VoucherSummaryParams) => {
    return assignmentAPI.exportAssignmentAndRedemptionList(params);
  }
);

export const exportAssignmentList = createAsyncThunk(
  "assignment/exportAssignmentList",
  (params: VoucherSummaryParams) => {
    return assignmentAPI.exportAssignmentList(params);
  }
);

export const exportRedemptionList = createAsyncThunk(
  "assignment/exportRedemptionList",
  (params: VoucherSummaryParams) => {
    return assignmentAPI.exportRedemptionList(params);
  }
);

export const exportSeatAssignmentList = createAsyncThunk(
  "assignment/exportSeatAssignmentList",
  (params: VoucherSummaryParams) => {
    return assignmentAPI.exportSeatAssignmentList(params);
  }
);

export const distribute = createAsyncThunk(
  "vouchers/distribute",
  assignmentAPI.distributeVouchers
);

export const getVoucherCodeInformation = createAsyncThunk(
  "vouchers/getVoucherCodeInformation",
  assignmentAPI.getVoucherCodeInformation
);

const voucherGroupsAdapter = createEntityAdapter<VoucherGroupModel>({
  selectId: (record) => record.voucherGroupId,
});

interface Filters {
  adminGroupId: number;
  voucherGroupId: string;
  voucherType: VoucherType;
}

const voucherGroupsSlice = createSlice({
  name: "voucher-groups",
  initialState: voucherGroupsAdapter.getInitialState({
    currentPage: 1,
    currentSize: 10,
    totalElements: 0,
    totalAssignElements: 0,
    totalRedeemElements: 0,
    assignmentAndRedemptionList: [] as AssignmentAndRedemptionListResponse[],
    seatAssignmentList: [] as AssignmentAndRedemptionListResponse[],
    assignmentList: [] as AssignmentModel[],
    redemptionList: [] as RedemptionModel[],
    redeemableProducts: [] as RedeemableProductModel[],
    voucherSummary: {} as VoucherSummaryResponse,
    actionToggle: false,
    filter: {} as Filters,
    voucherCodeInformation: {} as VoucherCodeInformation,
  }),
  reducers: {
    voucherUpdate: voucherGroupsAdapter.updateOne,
    setFilter: (state, { payload }: PayloadAction<Filters>) => {
      state.filter = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchVoucherDistributions.fulfilled,
      (state, { payload }) => {
        const { content, totalElements, page, size } = payload;
        voucherGroupsAdapter.setAll(state, content);
        state.currentPage = page + 1;
        state.currentSize = size;
        state.totalElements = totalElements;
      }
    );
    builder.addCase(
      fetchAssignmentAndRedemptionList.fulfilled,
      (state, { payload }) => {
        const { content, totalElements, page, size } = payload;
        state.currentPage = page + 1;
        state.currentSize = size;
        state.totalElements = totalElements;
        state.assignmentAndRedemptionList = content;
      }
    );
    builder.addCase(fetchSeatAssignmentList.fulfilled, (state, { payload }) => {
      const { content, totalElements, page, size } = payload;
      state.currentPage = page + 1;
      state.currentSize = size;
      state.totalElements = totalElements;
      state.seatAssignmentList = content;
    });
    builder.addCase(fetchAssignmentList.fulfilled, (state, { payload }) => {
      const { content, totalElements, page, size } = payload;
      state.currentPage = page + 1;
      state.currentSize = size;
      state.totalAssignElements = totalElements;
      state.assignmentList = content;
    });
    builder.addCase(fetchRedemptionList.fulfilled, (state, { payload }) => {
      const { content, totalElements, page, size } = payload;
      state.currentPage = page + 1;
      state.currentSize = size;
      state.totalRedeemElements = totalElements;
      state.redemptionList = content;
    });
    builder.addCase(fetchRedeemableProducts.fulfilled, (state, { payload }) => {
      state.redeemableProducts = payload;
    });
    builder.addCase(
      fetchVoucherSummary.fulfilled,
      (state, { payload, meta }) => {
        state.voucherSummary = payload;
        voucherGroupsAdapter.upsertOne(state, {
          ...payload,
          voucherGroupId: meta.arg.voucherGroupId,
        });
      }
    );
    builder.addCase(distribute.fulfilled, (state) => {
      state.actionToggle = !state.actionToggle;
    });
    builder.addCase(
      getVoucherCodeInformation.fulfilled,
      (state, { payload }) => {
        state.voucherCodeInformation = payload;
      }
    );
  },
});

export default voucherGroupsSlice.reducer;

export const { setFilter } = voucherGroupsSlice.actions;

export const voucherGroupsSelectors = voucherGroupsAdapter.getSelectors<
  RootState
>((state) => state.voucherGroups);
