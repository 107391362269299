/*eslint no-template-curly-in-string:*/
const typeTemplate = "'${label}' is not a valid ${type}";

// TODO: move to constants/messages.ts
export const validateMessages = {
  required: "'${label}' is required",
  string: {
    len: "Must be exactly ${len} characters",
    max: "Must be less than ${max} characters",
  },
  number: {
    min: "Must be more than ${min}",
    range: "Must be between ${min} and ${max}",
  },
  types: {
    string: typeTemplate,
    method: typeTemplate,
    array: typeTemplate,
    object: typeTemplate,
    number: typeTemplate,
    date: typeTemplate,
    boolean: typeTemplate,
    integer: typeTemplate,
    float: typeTemplate,
    regexp: typeTemplate,
    email: typeTemplate,
    url: typeTemplate,
    hex: typeTemplate,
  },
};

export const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const EMPTY = "--";

export const LEAVE_CONFIRMATION =
  "All changes will be lost if you leave this page without saving.";

export const DEFAULT_PAGINATION = {
  page: 0,
  size: 10,
};

export const DEFAULT_MAX_PAGINATION = {
  page: 0,
  size: 10000,
};

export const VOUCHER_TYPE_MAP: Map<string, string> = new Map([
  ["SINGLE", "Single"],
  ["MASTER", "Master"],
  ["SEAT", "Seat"],
]);

export const UNAUTHORIZED_MSG = {
  title: "Permission Denied",
  content: "Sorry, you don’t have permission to this website.",
};

export const Tooltip_MAP: Map<string, string> = new Map([
  ["Not started", "0%"],
  ["Beginning", "0~20%"],
  ["In progress", "20%~70%"],
  ["Almost completed", "70%~100%"],
  ["Completed", "100%"],
]);
